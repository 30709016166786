<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead class="thead-light">
        <th>Order Summary</th>
        <th>Order Status</th>
        <th>Qty &amp; Price</th>
        <th>Buyer Details</th>
        <th colspan="2">Dispatch Date</th>
      </thead>
      <tbody>
        <tr v-if="isLoading">
          <td colspan="5" align="center">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </td>
        </tr>
        <tr v-else-if="model.length == 0">
          <td colspan="5" align="center">No data in this list</td>
        </tr>
        <tr v-for="(o, i) in model" :key="i" :style="{
            ...(o.shipment_type == 1 ? {'background': '#ffeeba'} : {}),
            ...(o.shipment_penalty ? {'background': 'rgb(255, 204, 255)'} : {}),
          }"
          v-else
        >
          <td>
            <p>
              Order no: {{o.order_number}}<br/>
              Date: {{o.date}}<br/>
              Payment Date: {{o.payment_date_cust}}<br/>
              <span v-if="o.shipment_type == 1"
                class="badge badge-warning mr-1"
              >
                Pick-up Point
              </span>
              <template v-if="!/waiting|expire|cancel/i.test(o.status)">
                <span v-if="!/ready|delivery|received/i.test(o.status)"
                  class="badge badge-warning mr-1"
                >
                  Belum {{ /packing/i.test(o.status) ? 'kirim' : 'proses' }}
                </span>
                <span v-if="o.shipment_deadline <= new Date(o.sdate)"
                  class="badge mr-1"
                  style="background: rgb(255, 204, 255)"
                >
                  Terlambat
                </span>
              </template>
              <span v-if="o.shipment_penalty"
                class="badge badge-danger mr-1"
              >
                Penalti
              </span>
            </p>
          </td>
          <td>
            <p class="m-0">
              {{o.status}}<br>
              <span v-if="o.status == 'Packing'
                && o.pick_up_point_platform_name
                && o.pick_up_point_platform_name != 'OWN'
                && !o.sales_order_seller_pick_up_point
                && merchantData.merchant_pick_up_point_platform.name == 'OWN'
              "
                class="text-danger"
              >
                Courier info not set!
              </span>
              <span v-else-if="o.status == 'Requesting Pick Up'" class="text-danger">Mohon periksa/cetak packing slip!</span>
            </p>
            <button
              v-if="o.status == 'Order Verified' && merchantData.merchant_pick_up_point_platform.name == 'OWN'"
              type="button"
              class="btn btn-success"
              @click="$emit('updateStatus', {to: 1, data: o})"
            >Process Order</button>
            <template v-else-if="o.shipment_type == 0 && merchantData.merchant_pick_up_point_platform.name == 'OWN'">
              <template v-if="o.status == 'Packing'">
                <!-- NORMAL DELIVERY -->
                <button type="button" class="btn btn-success" @click="$emit('updateStatus', {to: 2, data: o})">Set Ready to Deliver</button>
                <!-- JUENE ONLY, JNE REQUEST PICKUP -->
                <button v-if="o.courier_code == 'jne' && ((isStaging || isLocal) ? [2] : [148]).includes(parseInt(merchantData.merchant_id))"
                  type="button" class="btn btn-success" @click="requestJnePickup(o)"
                >
                  <fawesome-pro icon="box-check" size="sm" /> Pickup JNE
                </button>
              </template>
              <template v-else-if="o.status == 'On Delivery'">
                <p>Tracking No: {{o.tracking_number}}</p>
                <button type="button" class="btn btn-success mb-1" @click="$emit('updateStatus', {to: 2, data: o})">Edit Tracking #</button><br/>
                <button type="button" class="btn btn-success" @click="trackOrder(o)" v-if="o.tracking_number">Track Order</button>
                <!-- <button type="button" class="btn btn-success" @click="$emit('trackOrder', o)" v-b-modal.modal-track-order>Track Order</button> -->
              </template>
            </template>
            <template v-else-if="o.shipment_type == 1 && o.pick_up_point_platform_name == merchantData.merchant_pick_up_point_platform.name && o.allow_process">
              <button v-if="o.status == 'Packing'" type="button" class="btn btn-success" @click="$emit('updateStatus', {to: 2, data: o})">Set Ready to Pickup</button>
              <button v-if="o.status == 'Ready To Pick Up'" type="button" class="btn btn-success" @click="$emit('updateStatus', {to: 2, data: o})">Enter Pickup Code</button>
            </template>
          </td>
          <td>
            <p>
              Variance: {{o.items.length}}<br/>
              Amount: Rp{{formatPrice(o.total)}}
            </p>
          </td>
          <td>
            <p class="m-0">{{o.buyer_name}} {{o.reseller_id ? '(Bus. Partner)' : ''}}<br/></p>
            <p class="mw-400px" v-if="o.shipment_type == 0" v-html="o.buyer_address.split('\n').join('<br/>')"></p>
            <p class="mw-400px" v-else v-html="`
              <b>Pickup Point Address:</b><br/>
              ${o.pick_up_point_name}<br/>
              ${o.pick_up_point_address}<br/>
              ${o.pick_up_point_contact}
            `"></p>
          </td>
          <td>
            <p>
              {{o.sdate}}
            </p>
          </td>
          <td class="td-dropdown">
            <b-dropdown variant="link" toggle-class="p-0 text-black" right no-caret>
              <template #button-content>
                <font-awesome icon="ellipsis-v" />
              </template>
              <b-dropdown-item v-b-modal.modal-order-detail @click="$emit('viewDetail', o.id)"><b>VIEW</b></b-dropdown-item>
              <template v-if="!/waiting|expire|cancel/i.test(o.status) && merchantData.merchant_pick_up_point_platform.name == 'OWN'">
                <b-dropdown-item @click="downloadPdf(o)"><b>INVOICE PDF</b></b-dropdown-item>
                <b-dropdown-item @click="packingSlip(o)"><b>PACKING SLIP PDF</b></b-dropdown-item>
              </template>
            </b-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
// import jsPDF from "jspdf";

export default {
  name: "OrderListTable",
  props: ["type", "orders", "isLoading"],
  data() {
    return {
      model: this.orders,
    }
  },
  computed: {
    isStaging() {
      return process.env.VUE_APP_API_URL.includes('staging');
    },
    isLocal() {
      return process.env.VUE_APP_API_URL.includes('localhost');
    },
    merchantData() {
      return this.$store.getters.getMerchantData
    }
  },
  methods: {
    // toDataURL(url, callback) {
    //   const xhr = new XMLHttpRequest();
    //   xhr.onload = () => {
    //     const reader = new FileReader();
    //     reader.onloadend = () => callback(reader.result);
    //     reader.readAsDataURL(xhr.response);
    //   };
    //   xhr.open('GET', url);
    //   xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
    //   xhr.responseType = 'blob';
    //   xhr.send();
    // },
    // async downloadPdf(order) {
    //   const id = order.id;
    //   try {
    //     const res = await this.$api.order.getOrderDetail(id)
    //     if (res.status === 200) {
    //       const d = res.data.data
    //       const doc = new jsPDF('p', 'mm', 'a5')
    //       const assets = async () => {
    //         // const background = await new Promise(resolve => this.toDataURL('assets/img/invoice.jpg', res => resolve(res)))
    //         const background = await new Promise(resolve => this.toDataURL(require('@/assets/img/invoice.jpg'), res => resolve(res)))
    //         const robotoRegular = await new Promise(resolve => this.toDataURL(require('@/assets/fonts/Roboto-Regular.ttf'), res => resolve(res)))
    //         const robotoBold = await new Promise(resolve => this.toDataURL(require('@/assets/fonts/Roboto-Bold.ttf'), res => resolve(res)))
    //         const robotoLight = await new Promise(resolve => this.toDataURL(require('@/assets/fonts/Roboto-Light.ttf'), res => resolve(res)))
    //         return {
    //           background,
    //           font: {
    //             regular: robotoRegular,
    //             bold: robotoBold,
    //             light: robotoLight
    //           }
    //         }
    //       }
    //       assets().then(uri => {
    //         doc.addFileToVFS("Roboto-Regular.ttf", uri.font.regular.toString().split(',')[1])
    //           doc.addFont("Roboto-Regular.ttf", "Roboto", "normal")
    //           doc.addFileToVFS("Roboto-Bold.ttf", uri.font.bold.toString().split(',')[1])
    //           doc.addFont("Roboto-Bold.ttf", "Roboto", "bold")
    //           doc.addFileToVFS("Roboto-Light.ttf", uri.font.light.toString().split(',')[1])
    //           doc.addFont("Roboto-Light.ttf", "Roboto", "light")
    //           doc.addImage(uri.background, "JPG", 0, 0, 148, 210)
              
    //           doc.setFont("Roboto")
    //           if(d.reseller_membership) {
    //             doc.setFontStyle("light")
    //             doc.setFontSize(10)
    //             doc.setTextColor(160, 116, 43)
    //             doc.text(d.reseller_membership.name + " Associate", 15, 19)
    //           }
    //           doc.setFontStyle("normal")
    //           doc.setFontSize(10)
    //           doc.setTextColor(0, 0, 0)
    //           doc.text(d.order_number.split('-')[1], 21, 30.3) // starting 32
    
    //           doc.setDrawColor("#d89700")
    //           doc.setLineWidth(0.2)
    //           doc.setFontSize(6)
    //           doc.setFontStyle("bold")
    //           doc.setTextColor("#d89700")
    
    //           doc.text('NAME', 15, 40) // 45 start
    //           doc.text(d.pick_up_point_address ? 'PICK-UP POINT' : 'SHIP TO', 55.4, 40)
    //           doc.text('CONTACT', 15, 51.5)
    //           doc.line(15, 62.9, 133, 62.9)
    //           doc.text('ORDER NUMBER', 15, 66.4)
    //           doc.text('ORDER DATE', 55.4, 66.4)
    //           doc.text('PAYMENT DATE', 105, 66.4)
    //           doc.line(15, 72, 133, 72) // should have a range with bottom 3.5mm
    //           doc.text('LIST ITEM', 15, 75.5)
    //           doc.text('QTY', 55.4, 75.5)
    //           doc.text('PRICE', 75, 75.5)
    //           doc.text('TOTAL', 105, 75.5)
    //           doc.line(15, 77.6, 133, 77.6)
    
    //           if (d.reseller_id) {
    //             doc.line(15, 113.3, 133, 113.3)
    //             doc.text('ASSOCIATE COMMISSION', 15, 116.8)
    //           }
    //           doc.line(15, 118.9, 133, 118.9)
    //           doc.text('SUB TOTAL', 15, 122.4)
    //           doc.line(15, 124.5, 133, 124.5)
    //           doc.text('HANDLING FEE', 15, 128)
    //           doc.line(15, 130.1, 133, 130.1)
    //           doc.text('DISCOUNT', 15, 133.6)
    //           doc.line(15, 135.7, 133, 135.7)
    //           doc.text('VOUCHER DISCOUNT', 15, 139.2)
    //           doc.line(15, 141.3, 133, 141.3)
    //           doc.text('VOUCHER SHIPPING', 15, 144.8)
    //           doc.line(15, 146.9, 133, 146.9)
    //           doc.text('SHIPPING COST', 15, 150.4)
    //           doc.line(15, 152.5, 133, 152.5)
    //           doc.text('LOYALTY POINT USED', 15, 156)
    
    //           doc.setTextColor('black')
    //           doc.setFontSize(8)
    //           doc.setFontStyle('light')
    //           // Top
    //           const name = doc.splitTextToSize(d.buyer_name, 40)
    //           doc.text(name, 15, 43.5)
    //           const address = d.shipping_address.name + '\n' +
    //             d.shipping_address.address + ', ' +
    //             d.shipping_address.city + ', ' +
    //             d.shipping_address.districts + ' ' +
    //             d.shipping_address.postal_code
    //           const line = doc.splitTextToSize(address, 77.6)
    //           doc.text(line, 55.4, 43.5)
    //           doc.setFontSize(7)
    //           doc.text('+62' + d.shipping_address.phone, 15, 55)
    //           const email = doc.splitTextToSize(d.buyer_email, 40)
    //           doc.text(email, 15, 58.5)
    //           // Mid
    //           doc.setFontSize(8)
    //           doc.text(d.date, 55.4, 69.9)
    //           let payDate = d.payment_date_cust.split('/')
    //           payDate = [payDate[2], payDate[0], payDate[1]].join('-')
    //           doc.text(payDate, 105, 69.9)
    //           doc.setFontStyle('normal')
    //           doc.text(d.order_number, 15, 69.9)
    
    //           // Start products
    //           doc.setFontSize(7)
    //           for (let i = 0; i < d.items.length; i++) {
    //             const item = d.items[i]
    //             const space = 7.8
    //             doc.setFontStyle('bold')
    //             doc.text(item.name, 15, 82 + i * space)
    //             doc.text(item.quantity < 10 ? '0' + item.quantity : item.quantity.toString(), 55.4, 82 + i * space)
    //             doc.text('Rp ' + item.price.toLocaleString('id'), 75, 82 + i * space)
    //             doc.text('Rp ' + (item.quantity * item.price).toLocaleString('id'), 105, 82 + i * space)
    //             doc.setFontStyle('light')
    //             doc.text(item.color + " / " + item.size, 15, 85.2 + i * space)
    //           }
    
    //           doc.setFontSize(8)
    //           if(d.reseller_id) {
    //             doc.text('Rp', 105, 116.8)
    //             doc.text(d.reseller_commission > 0 ? '- ' + d.reseller_commission.toLocaleString('id') : '0', 127, 116.8, 'right')
    //           }
    //           doc.text('Rp', 105, 122.5)
    //           doc.text(d.sub_total.toLocaleString('id'), 127, 122.5, 'right')
    //           doc.text('Rp', 105, 128.1)
    //           doc.text(d.handling_fee.toLocaleString('id'), 127, 128.1, 'right')
    //           doc.text('Rp', 105, 133.7)
    //           doc.text(d.discount > 0 ? '- ' + d.discount.toLocaleString('id') : '0', 127, 133.7, 'right')
    //           const voucherDis = d.voucher_info.filter(el => !el.voucher_name.includes('<Shipping>'))
    //           const voucherShip = d.voucher_info.filter(el => el.voucher_name.includes('<Shipping>'))
    //           doc.text('Rp', 105, 139.3)
    //           doc.text(voucherDis.length > 0 ? '- ' + voucherDis[0].value.toLocaleString('id') : '0', 127, 139.3, 'right')
    //           doc.text('Rp', 105, 144.9)
    //           doc.text(voucherShip.length > 0 ? '- ' + voucherShip[0].value.toLocaleString('id') : '0', 127, 144.9, 'right')
    //           doc.text('Rp', 105, 150.5)
    //           doc.text(d.shipping.toLocaleString('id'), 127, 150.5, 'right')
    //           doc.text('Rp', 105, 156.1)
    //           doc.text(d.seller_loyalty > 0 ? '- ' + d.seller_loyalty.toLocaleString('id') : '0', 127, 156.1, 'right')
    
    //           doc.setFontSize(6)
    //           doc.setFontStyle('bold')
    //           doc.text(voucherDis.length > 0 ? voucherDis[0].voucher_name : '', 55.4, 139.2)
    //           doc.text(voucherShip.length > 0 ? voucherShip[0].voucher_name : '', 55.4, 144.8)
    
    //           doc.setFontSize(15)
    //           doc.setFontStyle('bold')
    //           doc.text('Rp ' + d.total.toLocaleString('id'), 87.8, 164.6)
    //           // if(!d.reseller_id && d.send_invoice) {
    //           //   doc.setFontSize(8)
    //           //   doc.setFontStyle('normal')
    //           //   doc.text(d.order_number, 78.8, 192)
    //           // }
    //           doc.save('invoice-merchant-#' + d.order_number)
    //           // doc.output('dataurlnewwindow')
    //       })
    //     }
    //   } catch (e) {
    //     console.error(e)
    //   }
    // },
    async downloadPdf(order) {
      try {
        const res = await this.$api.order.downloadInvoice(order.id)
        if (res.status === 200) window.open(res.data.data, "_blank")
        else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
    trackOrder(order) {
      if(order.track_order) {
        this.$bvModal.show('modal-track-order');
        this.$emit('trackOrder', order);
      } else if(!order.track_order && order.track_order_url) {
        window.open(order.track_order_url + order.tracking_number, '_blank').focus();
      } else if(!order.track_order && !order.track_order_url) {
        return toaster.make("Untraceable");
      }
    },
    packingSlip(order) {
      window.open("/packing-slip/" + order.id, 'popup', 'width=1024,height=600')
    },
    async requestJnePickup(order) {
      try {
        const res = await this.$api.order.requestJnePickup(order);
        if (res.status === 200)
          this.$emit('refreshList')
        toaster.make(res.data.message, res.status === 200 ? 'success' : 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    formatPrice(value) {
      return value ? value.toLocaleString('id') : value
    },
  },
  watch: {
    orders(newVal) {
      this.model = newVal
    }
  }
}
</script>
<style scoped>
thead th {
  vertical-align: middle;
}
tbody {
  font-size: .865rem;
  white-space: nowrap;
}
tbody p {
  line-height: 22px;
  margin-bottom: .5rem;
}
.td-dropdown {
  padding: 1rem .25rem;
}
@media (min-width: 992px) {
  tbody {
    white-space: normal;
  }
  .table-responsive {
    overflow: initial;
  }
}
.mw-400px {
  max-width: 400px;
  text-align: justify;
  white-space: normal;
}
</style>
