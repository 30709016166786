<template>
  <div class="container-fluid">
    <MobileGuide />
    <div class="card p-2">
      <div class="row p-1 align-items-center">
        <div class="col-md-auto">
          <h2 class="roboto subtitle bold">Orders</h2>
        </div>
        <div class="col d-flex justify-content-md-end">
          <button class="btn" @click="refreshList">
            <font-awesome icon="redo-alt" />
          </button>
          <form
            @submit.prevent="searchOrder"
            class="input-search flex-grow-1 flex-md-grow-0"
          >
            <input
              id="search"
              type="text"
              placeholder="Search by order no."
              class="form-control"
            />
            <button class="btn"><font-awesome icon="search" /></button>
          </form>
        </div>
        <div class="col-12 pt-2" v-if="merchantData.merchant_pick_up_point_platform.name != 'OWN'">
          <InputMultiBox
            v-model="selectedOutlets"
            :options="myOutlets"
            placeholder="Select Outlets"
            checkbox
            :keys="['address', 'contact']"
          />
        </div>
      </div>
      <b-tabs fill>
        <!-- All -->
        <b-tab
          class="pt-5"
          @click="$router.push({ path: '/order-list', query: { t: 'all' } })"
          :active="currentTab == 'all'"
        >
          <template #title> All Orders ({{ listAll.total }}) </template>
          <OrderListTable
            @refreshList="refreshList"
            @updateStatus="currentEvent = $event"
            @viewDetail="selectedOrder = $event"
            @trackOrder="trackOrder = $event"
            :orders="listAll.orders"
            :isLoading="isLoading"
          />
          <b-pagination
            class="float-right"
            v-model="listAll.page"
            :total-rows="listAll.total"
            :per-page="listAll.limit"
            @change="getOrderList('all', listAll.limit, $event, search)"
          />
          <select
            v-model="listAll.limit"
            class="form-control w-auto float-right"
            @input="getOrderList('all', $event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab>
        <!-- Pending -->
        <b-tab
          class="pt-5"
          @click="$router.push({ path: '/order-list', query: { t: 'pending' } })"
          :active="currentTab == 'pending'"
        >
          <template #title> Pending ({{ listPending.total }}) </template>
          <OrderListTable
            @refreshList="refreshList"
            @updateStatus="currentEvent = $event"
            @viewDetail="selectedOrder = $event"
            :orders="listPending.orders"
            :isLoading="isLoading"
          />
          <b-pagination
            class="float-right"
            v-model="listPending.page"
            :total-rows="listPending.total"
            :per-page="listPending.limit"
            @change="getOrderList('pending', listPending.limit, $event, search)"
          />
          <select
            v-model="listPending.limit"
            class="form-control w-auto float-right"
            @input="getOrderList('pending', $event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab>
        <!-- New -->
        <b-tab
          class="pt-5"
          @click="$router.push({ path: '/order-list', query: { t: 'new' } })"
          :active="currentTab == 'new'"
        >
          <template #title> New ({{ listNew.total }}) </template>
          <OrderListTable
            @refreshList="refreshList"
            @updateStatus="currentEvent = $event"
            @viewDetail="selectedOrder = $event"
            :orders="listNew.orders"
            :isLoading="isLoading"
          />
          <b-pagination
            class="float-right"
            v-model="listNew.page"
            :total-rows="listNew.total"
            :per-page="listNew.limit"
            @change="getOrderList('new', listNew.limit, $event, search)"
          />
          <select
            v-model="listNew.limit"
            class="form-control w-auto float-right"
            @input="getOrderList('new', $event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab>
        <!-- Paid -->
        <b-tab
          class="pt-5"
          @click="$router.push({ path: '/order-list', query: { t: 'paid' } })"
          :active="currentTab == 'paid'"
        >
          <template #title> Processed ({{ listPaid.total }}) </template>
          <OrderListTable
            @refreshList="refreshList"
            @updateStatus="currentEvent = $event"
            @viewDetail="selectedOrder = $event"
            :orders="listPaid.orders"
            :isLoading="isLoading"
          />
          <b-pagination
            class="float-right"
            v-model="listPaid.page"
            :total-rows="listPaid.total"
            :per-page="listPaid.limit"
            @change="getOrderList('paid', listPaid.limit, $event, search)"
          />
          <select
            v-model="listPaid.limit"
            class="form-control w-auto float-right"
            @input="getOrderList('paid', $event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab>
        <!-- Packed -->
        <b-tab
          class="pt-5"
          @click="$router.push({ path: '/order-list', query: { t: 'packed' } })"
          :active="currentTab == 'packed'"
        >
          <template #title>
            On Delivery ({{ listPacked.total }})
          </template>
          <OrderListTable
            @refreshList="refreshList"
            @updateStatus="currentEvent = $event"
            @viewDetail="selectedOrder = $event"
            @trackOrder="trackOrder = $event"
            :orders="listPacked.orders"
            :isLoading="isLoading"
          />
          <b-pagination
            class="float-right"
            v-model="listPacked.page"
            :total-rows="listPacked.total"
            :per-page="listPacked.limit"
            @change="getOrderList('packed', listPacked.limit, $event, search)"
          />
          <select
            v-model="listPacked.limit"
            class="form-control w-auto float-right"
            @input="getOrderList('packed', $event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab>
        <!-- Delivered -->
        <b-tab
          class="pt-5"
          @click="$router.push({ path: '/order-list', query: { t: 'delivered' } })"
          :active="currentTab == 'delivered'"
        >
          <template #title> Delivered ({{ listDelivered.total }}) </template>
          <OrderListTable
            @refreshList="refreshList"
            @updateStatus="currentEvent = $event"
            @viewDetail="selectedOrder = $event"
            :orders="listDelivered.orders"
            :isLoading="isLoading"
          />
          <b-pagination
            class="float-right"
            v-model="listDelivered.page"
            :total-rows="listDelivered.total"
            :per-page="listDelivered.limit"
            @change="
              getOrderList('delivered', listDelivered.limit, $event, search)
            "
          />
          <select
            v-model="listDelivered.limit"
            class="form-control w-auto float-right"
            @input="getOrderList('delivered', $event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab>
        <!-- Cancelled -->
        <b-tab
          class="pt-5"
          @click="$router.push({ path: '/order-list', query: { t: 'cancelled' } })"
          :active="currentTab == 'cancelled'"
        >
          <template #title> Cancelled ({{ listCancelled.total }}) </template>
          <OrderListTable
            @refreshList="refreshList"
            @updateStatus="currentEvent = $event"
            @viewDetail="selectedOrder = $event"
            :orders="listCancelled.orders"
            :isLoading="isLoading"
          />
          <b-pagination
            class="float-right"
            v-model="listCancelled.page"
            :total-rows="listCancelled.total"
            :per-page="listCancelled.limit"
            @change="
              getOrderList('cancelled', listCancelled.limit, $event, search)
            "
          />
          <select
            v-model="listCancelled.limit"
            class="form-control w-auto float-right"
            @input="getOrderList('cancelled', $event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab>
        <!-- Late -->
        <b-tab
          class="pt-5"
          @click="$router.push('/late-order')"
          :active="currentTab == 'late'"
        >
          <template #title> Late ({{ listLate.total }}) </template>
          <OrderListTable
            @refreshList="refreshList"
            @updateStatus="currentEvent = $event"
            @viewDetail="selectedOrder = $event"
            :orders="listLate.orders"
            :isLoading="isLoading"
          />
          <b-pagination
            class="float-right"
            v-model="listLate.page"
            :total-rows="listLate.total"
            :per-page="listLate.limit"
            @change="
              getOrderList(null, listLate.limit, $event, search, l, true)
            "
          />
          <select
            v-model="listLate.limit"
            class="form-control w-auto float-right"
            @input="getOrderList(null, $event.target.value, 1, search, l, true)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab>
      </b-tabs>
    </div>
    <ModalOrderDetail :orderId="selectedOrder" @setCourier="onSetCourier" />
    <ModalTrackOrder :order="trackOrder" />
    <b-modal
      id="modal-update-status"
      hide-header
      centered
      @ok="updateStatus(currentEvent.to, currentEvent.data, code)"
    >
      <template v-if="currentEvent">
        <input
          v-if="currentEvent.data.shipment_type == 0"
          v-model.trim="code"
          type="text"
          class="form-control"
          placeholder="Enter Shipping Number/Tracking ID"
        />
        <p
          class="text-center m-0"
          v-else-if="currentEvent.data.status == 'Packing'"
        >
          Set this order as "Ready To Pickup"?
        </p>
        <input
          v-else
          v-model.trim="code"
          type="text"
          class="form-control"
          placeholder="Enter Pickup Code"
          @input="code = code.toUpperCase()"
        />
      </template>
    </b-modal>
  </div>
</template>
<script>
import ModalOrderDetail from "@/components/orderList/ModalOrderDetail";
import ModalTrackOrder from '@/components/orderList/ModalTrackOrder.vue';
import OrderListTable from "@/components/orderList/OrderListTable";
import MobileGuide from "@/components/navs/MobileGuide";
const InputMultiBox = () => import("@/components/formCustom/InputMultiBox");

import { toaster } from "@/_helpers";

export default {
  name: "OrderList",
  components: {
    ModalOrderDetail,
    ModalTrackOrder,
    OrderListTable,
    MobileGuide,
    InputMultiBox,
  },
  data() {
    return {
      isLoading: false,
      search: null,
      selectedOrder: null,
      trackOrder: null,
      currentEvent: null,
      currentTab: "all",
      code: null,
      myOutlets: [],
      selectedOutlets: [],
      listAll: {
        total: 0,
        orders: [],
        limit: 5,
        page: 1,
      },
      listPending: {
        total: 0,
        orders: [],
        limit: 5,
        page: 1,
      },
      listNew: {
        total: 0,
        orders: [],
        limit: 5,
        page: 1,
      },
      listPaid: {
        total: 0,
        orders: [],
        limit: 5,
        page: 1,
      },
      listPacked: {
        total: 0,
        orders: [],
        limit: 5,
        page: 1,
      },
      listDelivered: {
        total: 0,
        orders: [],
        limit: 5,
        page: 1,
      },
      listCancelled: {
        total: 0,
        orders: [],
        limit: 5,
        page: 1,
      },
      listLate: {
        total: 0,
        orders: [],
        limit: 5,
        page: 1,
      },
    };
  },
  computed: {
    urlPath() {
      return this.$route.path;
    },
    urlQuery() {
      return this.$route.query;
    },
    merchantData() {
      return this.$store.getters.getMerchantData;
    }
  },
  mounted() {
    this.setCurrentTab(this.urlQuery)
    this.refreshList();
    if (this.merchantData.merchant_pick_up_point_platform.name != 'OWN') this.getOutlets()
  },
  methods: {
    async getTotalList() {
      try {
        const params = {}, outlets = this.selectedOutlets.map(el => el.id);
        if (outlets.length) params.pickup_id = outlets.join(',');
        if (this.search?.trim()) params.query = this.search.trim();

        const res = await this.$api.order.getOrderListCount(params);
        if (res.status === 200) {
          this.listAll.total = res.data.data.total_all;
          this.listCancelled.total = res.data.data.total_cancelled;
          this.listDelivered.total = res.data.data.total_delivered;
          this.listLate.total = res.data.data.total_late;
          this.listNew.total = res.data.data.total_new;
          this.listPacked.total = res.data.data.total_packed;
          this.listPaid.total = res.data.data.total_paid;
          this.listPending.total = res.data.data.total_pending;
        } else this.$helpers.toaster.make(res.data.message, 'danger');
      } catch (e) {
        if (e.response) this.$helpers.toaster.make(e.response, 'danger');
        console.error(e);
      }
    },
    setCurrentTab(query) {
      this.currentTab = /all|pending|new|paid|packed|delivered|cancelled/.test(query.t)
        || this.urlPath.includes('/late-order')
          ? query.t ?? "late"
          : "all";
    },
    async getOutlets() {
      try {
        const res = await this.$api.user.getOutlets()
        if (res.status === 200) this.myOutlets = res.data.data
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        console.error(e);
      }
    },
    refreshList() {
      // this.getOrderCount();
      this.getTotalList();
      this.refreshCurrentOrderTab();
      // this.getOrderList(this.currentTab, this.listAll.limit, 1, this.search, pickupIds);
      // this.getOrderList("all", this.listAll.limit, 1, this.search, pickupIds);
      // this.getOrderList("pending", this.listPending.limit, 1, this.search, pickupIds);
      // this.getOrderList("new", this.listNew.limit, 1, this.search, pickupIds);
      // this.getOrderList("paid", this.listPaid.limit, 1, this.search, pickupIds);
      // this.getOrderList("packed", this.listPacked.limit, 1, this.search, pickupIds);
      // this.getOrderList("delivered", this.listDelivered.limit, 1, this.search, pickupIds);
      // this.getOrderList("cancelled", this.listCancelled.limit, 1, this.search, pickupIds);
      // this.getOrderList(null, this.listLate.limit, 1, this.search, pickupIds, true); // late order
    },
    refreshCurrentOrderTab() {
      const pickupIds = this.selectedOutlets.map(el => el.id).join(',')
      let list = "";
      switch (this.currentTab) {
        case "all":
          list = "listAll";
          break;
        case "pending":
          list = "listPending";
          break;
        case "new":
          list = "listNew";
          break;
        case "paid":
          list = "listPaid";
          break;
        case "packed":
          list = "listPacked";
          break;
        case "delivered":
          list = "listDelivered";
          break;
        case "cancelled":
          list = "listCancelled";
          break;
        case "late":
          list = "listLate";
          break;
      }
      this.getOrderList(list == 'listLate' ? null : this.currentTab, this[list].limit, 1, this.search, pickupIds, list == 'listLate' ? true : undefined);
    },
    searchOrder() {
      const el = document.getElementById("search");
      this.search = el.value;
      this.refreshList()
    },
    async updateStatus(status, order, code) {
      try {
        let res = null
        // // remove whitespace & make the letter to upper case for pickup code / tracking number 
        // if(code) code = code.toUpperCase().trim()

        const isPickupPoint = order.shipment_type == 1 && /Ready to Pick Up/i.test(order.status);

        // validate pickup code 
        if(isPickupPoint) {
          res = await this.$api.order.validatePickupCode(order.id, code)
        } 
        // update order status
        else {
          res = await this.$api.order.updateStatus(order.id, status, code)
        }
        if (res.status === 200) {
          toaster.make(res.data.message, 'success')
          if (status == 1) {
            this.listNew.total -= 1;
            this.listPaid.total += 1;
          } else if (status == 2 && isPickupPoint) {
            this.listPacked.total -= 1;
            this.listDelivered.total += 1;
          } else if (status == 2 && !isPickupPoint) {
            this.listPaid.total -= 1;
            this.listPacked.total += 1;
          }
          this.refreshCurrentOrderTab();
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        toaster.make('Something went wrong', 'danger')
        console.error(e);
      }
    },
    async getOrderList(type, limit, page, query, pickupIds, late_mark) {
      try {
        this.isLoading = true;
        const res = await this.$api.order.getOrderList(
          type,
          limit,
          page - 1,
          query,
          pickupIds,
          late_mark,
        );
        if (res.status === 200) {
          const d = res.data;
          // Add property allow_process = true for internal merchant (OWN) && shipment type is pickup point (for rendering set ready to pickup button for own merchant) 
          d.data.map(el => {
            if (el.pick_up_point_platform_name == 'OWN'  && el.shipment_type == 1) el.allow_process = true;
            return el;
          })
          let list = "listLate";
          switch (type) {
            case "all":
              list = "listAll";
              break;
            case "pending":
              list = "listPending";
              break;
            case "new":
              list = "listNew";
              break;
            case "paid":
              list = "listPaid";
              break;
            case "packed":
              list = "listPacked";
              break;
            case "delivered":
              list = "listDelivered";
              break;
            case "cancelled":
              list = "listCancelled";
              break;
          }
          this[list].total = d.meta.total;
          this[list].orders = d.data;
          this[list].page = page;
        } else toaster.make(res.data.message, 'danger')
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
    },
    onSetCourier(event) {
      this.listAll.orders.find(el => el.id == event.sales_order_seller_id).sales_order_seller_pick_up_point = event
      this.listNew.orders.find(el => el.id == event.sales_order_seller_id).sales_order_seller_pick_up_point = event
      this.listPaid.orders.find(el => el.id == event.sales_order_seller_id).sales_order_seller_pick_up_point = event
      this.listPacked.orders.find(el => el.id == event.sales_order_seller_id).sales_order_seller_pick_up_point = event
      this.listLate.orders.find(el => el.id == event.sales_order_seller_id).sales_order_seller_pick_up_point = event
    },
  },
  watch: {
    urlQuery(newVal) {
      this.setCurrentTab(newVal)
    },
    selectedOutlets() {
      // console.log(newVal);
      this.refreshList()
    },
    currentEvent(newVal) {
      if (newVal.to == 2) {
        this.code = newVal.data.tracking_number;
        this.$bvModal.show("modal-update-status");
      } else if (newVal.to == 1) this.updateStatus(newVal.to, newVal.data);
    },
    currentTab() {
      this.refreshCurrentOrderTab();
    }
  },
};
</script>
