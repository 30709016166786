<template>
  <b-modal
    id="modal-track-order"
    :title="'Track Order - ' + (order ? order.id : '') "
    modal-class="p-0"
    centered
    ok-only
  >
    <div v-if="isLoading" class="text-center">
      <font-awesome icon="spinner" size="10x" spin/>

    </div>
    <table v-else>
      <tr v-for="(manifest, i) in trackingData.manifest" :key="i">
        <td>{{manifest.manifest_date}}</td>
        <td>{{manifest.manifest_time}}</td>
        <td>{{manifest.manifest_description}}</td>
      </tr>
    </table>
  </b-modal>
</template>
<script>
export default {
  name: "ModalTrackOrder",
  props: ["order"],
  data() {
    return {
      isLoading: true,
      trackingData: null,
    }
  },
  methods: {
    async getTrackingData(order) {
      this.isLoading = true
      try {
        const res = await this.$api.order.trackOrder(order)
        if (res != null) this.trackingData = res
        console.log(res);
        // this.isLoading = false
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    }
  },
  watch: {
    order(newVal) {
      this.getTrackingData(newVal)
    }
  }
}
</script>
